.main {
  width: 100%;
  height: 100vh;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2em;
    }
  }
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.5rem !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.2rem !important;
  }
  h2 {
    font-size: 1.2em !important;
    text-align: center;
    margin: 10px;
  }
}
