.main {
  height: 60vh;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
  .text {
    font-size: 3.5rem;
    color: white;
    text-align: center;
    text-shadow: 0 0 25px black;
  }
}

.information1 {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  article {
    width: 60%;
    text-align: justify;
  }
}

.buttons {
  height: 20vh;
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .text {
    font-size: 3.2rem !important;
  }
  .information1 {
    article {
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .information1 {
    height: 70vh;
    article {
      width: 90%;
    }
  }
  .buttons {
    height: 15vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
