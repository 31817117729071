.heightDiv {
  height: 100vh !important;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(238, 238, 238);
  height: 390px;
  width: 100%;
  h2 {
    margin-left: 30px;
    font-size: 2.5rem;
    color: rgb(62, 57, 131);
  }
  .image {
    max-width: 40%;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }
}

.contact {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 25px 0;
  .contactBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 1px solid rgb(192, 192, 192);
    width: 400px;
    height: 350px;
    background-color: rgb(255, 255, 255);
    p {
      font-size: 20px;
      color: rgb(62, 57, 131);
      text-align: center;
    }
    a {
      color: rgb(62, 57, 131);
    }
    .link {
      margin: 5px !important;
    }
  }
}

@media (max-width: 900px) {
  .main {
    height: 300px;
  }
  .image {
    display: none;
  }
  .title {
    font-size: 2.2em !important;
  }
  .contact {
    height: 80vh;
    flex-direction: column;
    p {
      font-size: 1.1em !important;
    }
  }
}

@media (max-width: 600px) {
  .contactBox {
    height: 550px;
    width: 340px !important;
  }
}
