.footer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(15, 21, 51);
  color: white;
  span {
    font-size: 1em;
    margin-left: 20px;
  }
  img {
    margin-right: 30px;
    min-width: 10%;
    border-radius: 7px;
  }
}

@media (max-width: 600px) {
  span {
    font-size: 0.7em !important;
    margin-left: 20px;
  }
  .logo1 {
    margin-right: 30px;
    width: 80%;
    border-radius: 7px;
  }
}
