.logo1 {
  height: 50px;
  margin-right: 20px;
  border-radius: 6px;
}

.logo2 {
  height: 50px;
  border-radius: 6px;
}

@media (min-width: 900px) {
  .logo2 {
    display: none;
  }
}
